import { CampaignDiscountType } from '../../types';

export const getCampaignCardVariantFromTitle = (title: string) => {
  if (title.includes('volkswagen')) return 'volkswagen';
  if (title.includes('audi')) return 'audi';
  if (title.includes('skoda')) return 'skoda';
  return 'moller';
};

export const getDiscount = (discount: CampaignDiscountType) => {
  switch (discount.campaignDiscountOption) {
    case 'percentage': {
      return `${discount.percentage}% i avslag`;
    }
    case 'kroner': {
      return `${discount.kroner} kroner i avslag`;
    }
    case 'equipment': {
      return `Få med utstyr for ${discount.equipment}`;
    }
    case 'rate': {
      return `${discount.rate} i rente`;
    }
    case 'monthly': {
      return `${discount.monthly} kroner i måneden`;
    }
    case 'other': {
      return `${discount.other}`;
    }
    default:
      return '';
  }
};

export const variantColor: {
  [key: string]: { background: string; foreground: string; border: string };
} = {
  moller: { background: 'bg-[#eceff7]', foreground: 'text-black', border: 'border-[#0021F3]' },
  volkswagen: { background: 'bg-[#0b2b5b]', foreground: 'text-white', border: 'border-[#0b2b5b]' },
  'volkswagen-nyttekjoretoy': {
    background: 'bg-[#0b2b5b]',
    foreground: 'text-white',
    border: 'border-[#0b2b5b]',
  },
  skoda: { background: 'bg-[#0e3a2f]', foreground: 'text-white', border: 'border-[#0e3a2f]' },
  audi: { background: 'bg-[#000000]', foreground: 'text-white', border: 'border-[#000000]' },
};

export default getCampaignCardVariantFromTitle;
