import { CampaignSingularType } from '../types';
import { getDiscount } from './utils/campaignUtils';

interface Props {
  campaign: CampaignSingularType;
  itemNo?: number;
  variant?: string;
}

export function DiscountCard({ campaign, itemNo, variant = 'moller' }: Props) {
  if (
    !campaign.discount ||
    (campaign.discount.campaignDiscountOption === 'other' && !campaign.discount.other)
  )
    return null;

  return (
    <p className="mt-5 text-[24px] font-medium text-[#E1644B] md:mt-6 md:text-[32px]">
      {`${getDiscount(campaign.discount)}${itemNo ? new Array(itemNo).fill('*').join('') : ''}`}
    </p>
  );
}

export default DiscountCard;
