import { CampaignSingularType } from '../types/campaignSingularType';
import Container from './Container';
import { DiscountCard } from './DiscountCard';
import { Image } from './Image';
import PortableText from './PortableText';

interface CampaignCardProps {
  campaign: CampaignSingularType;
  itemNo?: number;
  variant?: 'moller' | 'volkswagen' | 'skoda' | 'audi';
}

function CampaignBox({ campaign, itemNo, variant = 'moller' }: CampaignCardProps) {
  return (
    <Container noPadding className="my-3 md:my-8">
      <article className="flex w-full bg-[#EFE9E1] py-8 md:py-12">
        <div className="flex w-full flex-col gap-6 pl-12 pr-5 sm:pl-20 sm:pr-[64px]">
          {campaign.title && (
            <h2 className="md:text-display-2 mr-5 text-[28px] font-medium text-[#001946]">
              {campaign.title}
            </h2>
          )}
          {campaign.image?.asset && (
            <div className="relative sm:h-[380px] md:h-[450px] lg:h-[425px]">
              <div
                style={{
                  objectFit: 'cover',
                }}
                className="mr-[-20px] sm:mr-[-64px]"
              >
                <Image width={730} height={422} src={campaign.image} />
              </div>
            </div>
          )}
          <div className="mr-5 gap-6">
            {campaign.pitch && <p className="text-display-4">{campaign.pitch}</p>}
            {campaign.discount && (
              <DiscountCard campaign={campaign} itemNo={itemNo} variant={variant} />
            )}
            {campaign.extendedPitch && (
              <div>
                <PortableText value={campaign.extendedPitch} />
              </div>
            )}
          </div>
        </div>
      </article>
    </Container>
  );
}

export default CampaignBox;
