interface DisclaimerProps {
  text: string | undefined;
}

function Disclaimer({ text }: DisclaimerProps) {
  if (!text || text === '\n') return null;

  return <p className="my-12 whitespace-pre-line text-sm text-gray-500">{text}</p>;
}

export default Disclaimer;
